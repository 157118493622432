<template>
  <div class="card" v-if="type == 'report'">
    <div class="card-head">
      <div class="card-head-title font-weight-bold">
        <span>
          <v-icon color="#048CC3" size="24">mdi-chart-line</v-icon>
        </span>
        <span class="ml-2">分析・レポート</span>
      </div>
      <div class="mt-2">
        タスク実施率、時間内実施率の詳細を確認できます。<br />
        タスク、日報、チェックシート、臨時タスク、個人タスクの実施データを確認できます。
      </div>
      <div class="d-flex flex-wrap">
        <div class="card-link mt-4">
          <button @click="pageMove('/new-report')">レポート</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-else-if="type == 'dashboard'">
    <div class="card-head">
      <div class="card-head-title font-weight-bold">
        <span>
          <v-icon color="#048CC3" size="24">mdi-gauge</v-icon>
        </span>
        <span class="ml-2">ダッシュボード</span>
      </div>
      <div class="mt-2">
        ブランド、担当マネージャー、エリアごとに実施状況を確認できます。
      </div>
      <div class="d-flex flex-wrap">
        <div class="card-link mt-4">
          <button @click="pageMove('dashboard/task')">日常業務</button>
        </div>
        <div class="card-link mt-4">
          <button @click="pageMove('dashboard/emergency')">臨時タスク</button>
        </div>
        <div class="card-link mt-4">
          <button @click="pageMove('dashboard/checksheet')">
            チェックシート
          </button>
        </div>
        <div class="card-link mt-4">
          <button @click="pageMove('dashboard/dailyreport')">日報</button>
        </div>
        <div class="card-link cancel mt-4">
          <button @click="pageMove('dashboard-beta')">ベータ版</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-else-if="type == 'confirmation'">
    <div class="card-head">
      <div class="card-head-title font-weight-bold">
        <span>
          <v-icon color="#048CC3" size="24">mdi-store-check-outline</v-icon>
        </span>
        <span class="ml-2">実施状況</span>
      </div>
      <div class="mt-2">店舗、タスクごとに実施状況を確認できます。</div>
      <div class="d-flex flex-wrap">
        <div class="card-link mt-4">
          <button @click="pageMove('confirmation/task')">日常業務</button>
        </div>
        <div class="card-link mt-4">
          <button @click="pageMove('confirmation/emergency')">
            臨時タスク
          </button>
        </div>
        <div class="card-link mt-4">
          <button @click="pageMove('confirmation/personal_task')">
            個人タスク
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-else-if="type == 'request'">
    <div class="card-head">
      <div class="card-head-title font-weight-bold">
        <span>
          <v-icon color="#048CC3" size="24">mdi-hand-back-right-outline</v-icon>
        </span>
        <span class="ml-2">店舗からのリクエスト</span>
      </div>
      <div class="mt-2">店舗からのリクエストを確認できます。</div>
      <div class="d-flex flex-wrap">
        <div class="card-link mt-4">
          <button @click="pageMove('request')">店舗からのリクエスト</button>
        </div>
      </div>
    </div>
  </div>
  <div class="card" v-else-if="type == 'ranking'">
    <div class="card-head">
      <div class="card-head-title font-weight-bold">
        <span>
          <v-icon color="#048CC3" size="24">mdi-crown</v-icon>
        </span>
        <span class="ml-2">ランキング</span>
      </div>
      <div class="mt-2">
        ブランドごとに、店舗のタスク実施率・時間帯実施率の順位を確認できます。
      </div>
      <div class="d-flex flex-wrap">
        <div class="card-link mt-4">
          <button @click="pageMove('ranking')">ランキング</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  setup() {
    const { pageMove } = require("@/helpers/common/common_util");
    return {
      pageMove,
    };
  },
};
</script>
