<template>
  <div>
    <CommonHeader :parents="parents"></CommonHeader>
    <AppAlert
      v-if="parents.notifications_for_bar"
      :notifications_for_bar="parents.notifications_for_bar"
    />
    <div class="sp-container">
      <div class="mb-4 fs-20 font-weight-bold">店舗の状況確認</div>
      <div class="sp-card" v-if="isHavingAdminManagerRoles()">
        <div class="sp-card-title">
          <span>
            <v-icon color="#048CC3" size="24">mdi-chart-line</v-icon>
          </span>
          <span class="ml-2">分析・レポート</span>
        </div>
        <div class="sp-card-text">
          レポート画面が新しくなりました！<br />
          全体の状況も、個別店舗の詳細も、より確認しやすくなりました。
        </div>
        <div class="d-flex flex-wrap">
          <div class="sp-card-link">
            <a @click="pageMove('/new-report')" class="px-4 py-2 mr-4">レポート</a>
          </div>
        </div>
      </div>
      <div class="sp-card" v-if="isHavingAdminManagerRoles()">
        <div class="sp-card-title">
          <span>
            <v-icon color="#048CC3" size="24">mdi-gauge</v-icon>
          </span>
          <span class="ml-2">ダッシュボード</span>
        </div>
        <div class="sp-card-text">
          ブランド、エリアごとに実施状況を確認できます。
        </div>
        <div class="d-flex flex-wrap">
          <div class="sp-card-link">
            <a @click="pageMove('dashboard')" class="px-4 py-2 mr-4">ダッシュボード</a>
          </div>
        </div>
      </div>
      <div class="sp-card" v-if="isHavingAdminManagerRoles() && isUseRequest()">
        <div class="sp-card-title">
          <span>
            <v-icon color="#048CC3" size="24">mdi-hand-back-right-outline</v-icon>
          </span>
          <span class="ml-2">店舗からのリクエスト</span>
        </div>
        <div class="sp-card-text">
          店舗からのリクエストを確認できます。
        </div>
        <div class="d-flex flex-wrap">
          <div class="sp-card-link">
            <a @click="pageMove('request')" class="px-4 py-2 mr-4">店舗からのリクエスト</a>
          </div>
        </div>
      </div>

      <div class="mt-10 mb-4 fs-20 font-weight-bold">設定・管理</div>
      <div class="sp-card" v-if="isHavingAdminManagerRoles()">
        <div class="sp-card-title">臨時タスク設定</div>
        <div class="sp-card-text">
          いつもの業務に、緊急の指示を差し込むことができます。
        </div>
        <div class="d-flex flex-wrap">
          <div class="sp-card-link">
            <a @click="pageMove('/manage/emergency')" class="px-4 py-2 mr-4">臨時タスク</a>
          </div>
        </div>
      </div>
      <div class="sp-card" v-if="isHavingAdminManagerRoles()">
        <div class="sp-card-title">個人タスク設定</div>
        <div class="sp-card-text">
          個別のユーザーに、業務を指示することができます。
        </div>
        <div class="d-flex flex-wrap">
          <div class="sp-card-link">
            <a @click="pageMove('/manage/personal_task')" class="px-4 py-2 mr-4">個人タスク</a>
          </div>
        </div>
      </div>
    </div>
    <CommonFooter />
  </div>
</template>

<script>
import CommonHeader from "@/components/service/SpHeader";
import CommonFooter from "@/components/service/SpFooter";
import AppAlert from "@/components/timemanagement/SpAppAlert.vue";
export default {
  components: { CommonHeader, CommonFooter, AppAlert },
  props: ["parents"],  setup() {
    const { pageMove, isUseRequest } = require('@/helpers/common/common_util')
    const { isHavingAdminManagerRoles, isNewTaskScheduleUi } = require('@/helpers/timemng/timemng_util')
    return {
      pageMove, isUseRequest,
      isHavingAdminManagerRoles, isNewTaskScheduleUi,
    }
  },
};
</script>

