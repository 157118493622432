import config from '@/configs/timemanagement';
import Index from '@/components/timemanagement/Index';
export default
    [

        {
            path: config.basepath + '/myshop/calendar',
            component: Index,
            children: [
                {
                    path: '',
                    name: 'timemanagement-myshop-calendar',
                    component: () => import('@/components/timemanagement/myshop/calendar/MyShopCalendar'),
                    meta: { title: 'My店舗 カレンダー', policy: { is_head: false } },
                },
                {
                    path: ':date',
                    name: 'timemanagement-myshop-calendar-date',
                    component: () => import('@/components/timemanagement/myshop/calendar/MyShopCalendar'),
                    meta: { title: 'My店舗 カレンダー', policy: { is_head: false } },
                },
            ],
        },
        {
            path: config.basepath + '/myshop/manage/task',
            component: Index,
            children: [
                {
                    path: '',
                    name: 'timemanagement-myshop-task',
                    component: () => import('@/components/timemanagement/myshop/task/IndexMyShopTask'),
                    meta: { title: 'My店舗 タスク', policy: { function_cd: "myshop_task", crud: ['c', 'r', 'u', 'd'], is_head: false, is_sp: false, disabled_sharemode: true } },
                },
            ]
        },
        {
            path: config.basepath + '/myshop/manage/template',
            component: Index,
            children: [
                {
                    path: '',
                    name: 'timemanagement-myshop-template',
                    component: () => import('@/components/timemanagement/myshop/template/IndexMyShopTaskTemplate'),
                    meta: { title: 'My店舗 タスクグループ', policy: { function_cd: "myshop_template", crud: ['c', 'r', 'u', 'd'], is_head: false, is_sp: false, disabled_sharemode: true } },
                },
            ]
        },
        {
            path: config.basepath + '/myshop/manage/pattern',
            component: Index,
            children: [
                {
                    path: '',
                    name: 'timemanagement-myshop-schedule',
                    component: () => import('@/components/timemanagement/myshop/schedule/IndexMyShopSchedule'),
                    meta: { title: 'My店舗 業務スケジュール', policy: { function_cd: "myshop_pattern", crud: ['c', 'r', 'u', 'd'], is_head: false, is_sp: false, disabled_sharemode: true } },
                },
            ]
        },
        {
            path: config.basepath + '/myshop/manage/checksheet',
            component: Index,
            children: [
                {
                    path: '',
                    name: 'timemanagement-myshop-checksheet',
                    component: () => import('@/components/timemanagement/myshop/checksheet/IndexMyShopChecksheet'),
                    meta: { title: 'My店舗 チェックシート', policy: { function_cd: "myshop_checksheet", crud: ['c', 'r', 'u', 'd'], is_head: false, is_sp: false, disabled_sharemode: true } },
                },
            ]
        },
        {
            path: config.basepath + '/myshop/manage/emergency',
            component: Index,
            children: [
                {
                    path: '',
                    name: 'timemanagement-myshop-emergency',
                    component: () => import('@/components/timemanagement/myshop/emergency/IndexMyShopEmergency'),
                    meta: { title: 'My店舗 臨時タスク', policy: { function_cd: "myshop_emergency", crud: ['c', 'r', 'u', 'd'], is_head: false, disabled_sharemode: true } },
                },
            ]
        },
        {
            path: config.basepath + '/myshop/manage/emergency/regist',
            component: Index,
            children: [
                {
                    path: '',
                    name: 'timemanagement-myshop-emergency-regist',
                    component: () => import('@/components/timemanagement/myshop/emergency/IndexMyShopEmergency'),
                    meta: { title: 'My店舗 臨時タスク', policy: { function_cd: "myshop_emergency", crud: ['c', 'r', 'u', 'd'], is_head: false, disabled_sharemode: true }, initmode: "regist" },
                },
            ]
        },
        {
            path: config.basepath + '/myshop/request',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'myshop-request',
                    component: () => import('@/components/timemanagement/request/IndexRequest'),
                    meta: { title: '本部にリクエスト', is_head: false, policy: { is_head: false} },
                }
            ]
        },
    ]
