import config from '@/configs/timemanagement';
import HeadShopHome from '@/components/timemanagement/headshop/home/HeadShopHome';
export default
    [
        {
            path: config.basepath + '/',
            name: 'timemanagement-headshop-home',
            component: HeadShopHome,
            meta: { title: 'ホーム', policy: { is_head: true } },
        },
        {
            path: config.basepath + '/ranking',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'ranking',
                    component: () => import('@/components/timemanagement/ranking/IndexRanking.vue'),
                    meta: { title: 'ランキング', policy: { is_sp: false } },
                }
            ]
        },
        {
            path: config.basepath + '/confirmation/task/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-headshop-confirmation-task',
                    component: () => import('@/components/timemanagement/headshop/confirmation/task/IndexConfirmationTask.vue'),
                    meta: { title: '店舗のタスク実施状況', policy: { is_head: true, is_sp: false } },
                }
            ]
        },
        {
            path: config.basepath + '/confirmation/emergency/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-headshop-confirmation-emergency',
                    component: () => import('@/components/timemanagement/headshop/confirmation/emergency/IndexConfirmationEmergency.vue'),
                    meta: { title: '店舗の臨時タスク実施状況', policy: { is_head: true, is_sp: false } },
                }
            ]
        },
        {
            path: config.basepath + '/confirmation/personal_task/',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'timemanagement-headshop-confirmation-personal-task',
                    component: () => import('@/components/timemanagement/headshop/confirmation/personal_task/IndexConfirmationPersonalTask.vue'),
                    meta: { title: '店舗の個別タスク実施状況', policy: { is_head: true, is_sp: false } },
                }
            ]
        },
        {
            path: config.basepath + '/request',
            component: () => import('@/components/timemanagement/Index'),
            children: [
                {
                    path: '',
                    name: 'request',
                    component: () => import('@/components/timemanagement/request/IndexRequest'),
                    meta: { title: '店舗からのリクエスト', is_head: true, policy: { is_head: true } },
                }
            ]
        },
    ]



