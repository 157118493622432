import config from "@/configs/service"
import storeAuth from "@/helpers/store/storeAuthHelper"
import storeSystem from "@/helpers/store/storeSystemHelper"
import { copyJson, isVManageUsingShop, isSystemApp, isUseRequest } from "@/helpers/common/common_util"
import { isUserHeadShop, isShopShareMode } from "@/helpers/auth/auth_util";
import { isEnabledRoleCd, isEnabledCompanyMultiShopManage } from "@/helpers/common/role";
import {
  getCompanySpecsRefOtherDailyReports, getCompanySpecsUseRanking,
  getCompanySpecsTaskScheduleUiVer
} from "@/helpers/auth/spec";

// メインタイトル取得
export function getServiceBaseTitle() {
  return config.maintitle
}
// サービス基準パス
export function getServiceBasePath() {
  return config.basepath
}
// ベースカラー
export function getServiceBaseColor() {
  return config.basecolor.base
}
export function getServiceBaseTextColor() {
  return config.basecolor.text
}
export function getServiceBaseBackColor() {
  return config.basecolor.background
}
export function isServiceBaseColorDark() {
  return config.basecolor.isdark
}
export function getServiceBaseContainerBackColor() {
  return config.basecolor.container_background
}
export function getServiceBaseInputColor() {
  return config.basecolor.input
}
export function getServiceBaseShopShareModeColor() {
  return config.basecolor.shop_share_mode
}
export function getHeaderLinks() {
  return config.menus
}

// MEMO:サービス毎にロジックを書き換える
export function getEnabledHeaderLinks(selected) {
  let links = []
  Object.keys(getHeaderLinks()).forEach(key => {
    let link = copyJson(getHeaderLinks()[key]);
    link.color_class = "";
    if (link.is_head != undefined) {
      if (link.is_head && !isUserHeadShop()) return
      if (!link.is_head && isUserHeadShop()) return
    }
    if (link.is_app != undefined) {
      if (link.is_app && !isSystemApp()) return
      if (!link.is_app && isSystemApp()) return
    }
    if (link.role_cds) {
      if (!isEnabledRoleCd(link.role_cds)) return
    }
    if (link.is_shop_multishops) {
      if (!isUserHeadShop() && !isEnabledCompanyMultiShopManage()) return
    }
    if (link.is_sharemode == true) {
      if (!isShopShareMode()) return
    } else if (link.is_sharemode == false) {
      if (isShopShareMode()) return
    }
    if (link.iplimited != undefined) {
      if (storeAuth.storeGetShopIpAddressLimited() == link.iplimited) return
    }

    // V-Manage利用中判定
    if (["dashboard", "areamanage", "routine_dailyreport", "other_shops"].includes(key)) {
      if (!isUserHeadShop() && !isVManageUsingShop(false)) return
    }

    // 他店舗日報は企業設定参照
    if (key == 'other_shops') {
      if (getCompanySpecsRefOtherDailyReports() == "0") return
    }

    // ランキングは企業設定参照
    if (key == 'ranking') {
      const ranking = getCompanySpecsUseRanking()
      if (isUserHeadShop()) {
        if (!ranking.head) return
      } else {
        if (!ranking.shop) return
      }
    }

    // リクエストは企業設定参照
    if (key == 'request' || key == 'shop_request') {
      if (!isUseRequest()) return
    }

    // サブリンク
    if (key == "routine_checksheet") {
      // チェックシート
      const display_setting = storeSystem.storeGetTodayTaskDisplaySetting();
      storeAuth.storeGetMenuChecksheets().forEach(v => {
        let name = v.checksheet.checksheet_name
        // 言語変換
        if (display_setting && display_setting.translate_lang && v.translate) {
          const data = v.translate[display_setting.translate_lang]
          if (data) {
            const translate_value = data['checksheet_name']
            if (translate_value && translate_value != '') name = translate_value;
          }
        }
        if (v.checksheet.status !== 0) return
        link.sublinks.push({
          name: name,
          to: "/routine/checksheet/" + v.checksheet.checksheet_id,
        })
      })
      if (link.sublinks.length == 0) return
    } else if (link.sublinks) {
      let sublinks = []
      link.sublinks.forEach(sublink => {
        if (sublink.is_head != undefined) {
          if (sublink.is_head && !isUserHeadShop()) return
          if (!sublink.is_head && isUserHeadShop()) return
        }
        if (sublink.is_app != undefined) {
          if (sublink.is_app && !isSystemApp()) return
          if (!sublink.is_app && isSystemApp()) return
        }
        if (sublink.is_sharemode == false) {
          if (isShopShareMode()) return
        }
        if (sublink.role_cds) {
          if (!isEnabledRoleCd(sublink.role_cds)) {
            return
          }
        }
        if (sublink.thirdlinks) {
          let thirds = []
          sublink.thirdlinks[getCompanySpecsTaskScheduleUiVer()].forEach(third => {
            if (third.role_cds && !isEnabledRoleCd(third.role_cds)) {
              return
            }
            thirds.push(third)
          })
          if (thirds.length > 0) {
            sublink.thirdlinks = thirds
            sublinks.push(sublink)
          }
        } else {
          sublinks.push(sublink)
        }
      })
      if (sublinks.length <= 0) {
        return
      }
      link.sublinks = sublinks
      link.color_class += "toolbar-various-settings ";
    }

    if (key == selected) {
      link.color_class += "active";
    }
    link.key = key;
    links.push(link)
  })

  // ソート
  links.sort((a, b) => {
    const an = !isUserHeadShop() && a.shop_sort_number ? a.shop_sort_number : a.sort_number;
    const bn = !isUserHeadShop() && b.shop_sort_number ? b.shop_sort_number : b.sort_number;
    if (an > bn) return 1;
    if (an < bn) return -1;
    return 0;
  });
  return links
}

export default {
  getServiceBaseTitle,
  getServiceBasePath,
  getServiceBaseColor,
  getServiceBaseTextColor,
  getServiceBaseBackColor,
  isServiceBaseColorDark,
  getServiceBaseContainerBackColor,
  getServiceBaseInputColor,
  getServiceBaseShopShareModeColor,
  getEnabledHeaderLinks,
  getHeaderLinks,
}
