import common from "@/configs/common"
import commonApi from "@/helpers/common/common_api"
import timemngApi from "@/helpers/timemng/timemng_api"
import storeAuth from "@/helpers/store/storeAuthHelper"
import { errorLog } from "@/helpers/common/datadog"
import { redirectPortal } from "@/helpers/timemng/timemng_util"
import { isUserHeadShop } from "@/helpers/auth/auth_util"
import {
  attachedFileDownload, isDisplayModeSP, pageMove,
  getRouteName, startExecute, finishExecute, getNewDate, isUseRequest,
} from "./common_util";
import { isServicePortal, isServiceTimemng } from "@/helpers/environment";

// システムバーに表示するお知らせ読み込み
export async function readNotificationsListForBar() {
  let notifications = null
  try {
    const results = await timemngApi.apiNotificationGetBarList();
    if (results.length > 0) {
      notifications = results[0]
    }
  } catch (err) {
    errorLog(getRouteName(), "readNotificationsListForBar", err);
  }
  return notifications
}

export function isLinkNotification(item) {
  return _getNotificationEvent(item)
}

// お知らせリンク選択時制御
export async function execLinkNotification(self, item) {
  const service_type = item.message.service_type_raw ?? item.message.service_type
  const event = _getNotificationEvent(item, true)
  if (event) {
    if (event.type == "download") {
      // ファイルダウンロード
      startExecute(self);
      try {
        const results = await commonApi.apiNotificationDownloadDataFile(service_type, item.message.specific_id);
        let filename = results.headers["content-disposition"];
        filename = decodeURI(
          filename.replace('attachment; filename="', "").slice(0, -1)
        );
        attachedFileDownload(results.data, filename);
      } catch {
        self.error = "指定されたファイルはダウンロード出来ません。";
      }
      finishExecute(self);
    } else if (event.type == "portal") {
      // ポータル画面遷移
      if (isServicePortal()) {
        pageMove(event.path)
      } else {
        redirectPortal(self, event.path) // eslint-disable-line
      }
    } else if (event.type == "timemng") {
      // タイマネ画面遷移
      if (isServiceTimemng()) {
        pageMove(event.path)
      } else {
        redirectTimemanagement(self, event.path) // eslint-disable-line
      }
    }
  }
}

// イベント取得
function _getNotificationEvent(item, is_full = false) {
  const service_type = item.message.service_type_raw ?? item.message.service_type
  const event = NotificationEvents[service_type]
  if (event) {
    if (event.not_sp && isDisplayModeSP()) return
    if (event.with_specific) {
      if (item.message.specific_id) {
        return {
          type: event.type,
          path: event.path + item.message.specific_id
        }
      }
    } else {
      return event
    }
  } else if (is_full && service_type == "application") {
    return {
      type: "timemng",
      path: isUserHeadShop() ? "/request" : "/myshop/request"
    }
  } else if (service_type == "download_checksheet" || service_type == "download_task") {
    let limit = getNewDate(item.message.published_at)
    limit.setDate(limit.getDate() + common.notification.download_limit)
    if (limit.getTime() >= new Date().getTime()) {
      return {
        type: "download",
      }
    }
  }
}

// お知らせ情報読み込み
export async function reloadNotificationStats() {
  const stats = await commonApi.apiNotificationGetStats()
  storeAuth.storeSetNotificationStats(stats)
}
export async function readNotificationStats(is_reload = false) {
  let stats = storeAuth.storeGetNotificationStats()
  if (is_reload) {
    const _stats = await commonApi.apiNotificationGetStats()
    storeAuth.storeSetNotificationStats(_stats)
    stats = _stats
  }
  return stats
}

// 最新お知らせ存在
export function isNewerNotifications() {
  try {
    const stats = storeAuth.storeGetNotificationStats()
    return stats && stats.find(v => v.oldnew == "new" && v.count > 0) ? true : false
  } catch (err) {
    errorLog(getRouteName(), "isNewerNotifications", err);
    return false
  }
}

// サービス種別
export function getNotificationServiceTypes() {
  return NotificationServiceTypes.filter(v => 
    (v.is_head == undefined || v.is_head == isUserHeadShop()) && 
    (v.key != 'application' || (v.key == 'application' && isUseRequest()))
  )
}

export default {
  readNotificationsListForBar,
  isLinkNotification,
  execLinkNotification,
  reloadNotificationStats,
  readNotificationStats,
  isNewerNotifications,
  getNotificationServiceTypes,
}

// お知らせ遷移イベント
const NotificationEvents = {
  forum: { type: "portal", path: "/forum/", with_specific: true },
  // upload_brand: { type: "portal", path: "/master/brand", not_sp:true },
  upload_area: { type: "portal", path: "/master/area", not_sp: true },
  upload_shop: { type: "portal", path: "/master/shop", not_sp: true },
  upload_facility: { type: "portal", path: "/master/facility", not_sp: true },
  upload_employee: { type: "portal", path: "/master/employee", not_sp: true },
  upload_task: { type: "timemng", path: "/manage/task", not_sp: true },
  // upload_task_sub: { type: "timemng", path: "/manage/task" },
  // upload_template: { type: "timemng", path: "/manage/task" },
  pattern_expired: { type: "timemng", path: "/manage/pattern", not_sp: true },
  pattern_expired_shop: { type: "timemng", path: "/myshop/manage/pattern", not_sp: true },
  checksheet: { type: "timemng", path: "/routine/checksheet/", with_specific: true },
}

// お知らせ表示種別
const NotificationServiceTypes = [
  { key: "emergency", text: "臨時タスク", is_head: false },
  { key: "shop", text: "新スタッフ追加" },
  { key: "forum", text: "掲示板投稿" },
  { key: "application", text: "リクエスト" },
  { key: "checksheet", text: "チェックシート" },
  { key: "patterns", text: "業務スケジュール" },
  { key: "updl", text: "ファイルUL・DL" },
  { key: "system", text: "運営会社からのお知らせ" },
]