import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    getList(apiconf, staff_id, tag_type, page) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tags/list",
            query: { staff_id: staff_id, tag_type: tag_type, page: page },
        })
    },

    // タグマスタ一覧取得
    getMasterList(apiconf, company_id, staff_id, tags) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tag_master/list",
            query: { company_id: company_id, staff_id: staff_id, tags: tags },
        })
    },

    // 新規作成
    create(apiconf, staff_id, tags) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tag/event/add/tags",
            query: { staff_id: staff_id, tags: tags },
        })
    },

    // 更新
    update(apiconf, staff_id, tags) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tag/event/update/tags",
            query: { staff_id: staff_id, tags: tags },
        })
    },

    // 削除
    delete(apiconf, staff_id, tag_id) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "tag/event/delete/tags",
            query: { staff_id: staff_id, tag_id: tag_id },
        })
    },
}
