import api_common from "@/helpers/api_common.js";

export default {
    // 一覧取得
    search(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "application/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },

    // 新規作成
    create(apiconf, staff_id, application) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "application/create",
            query: { staff_id: staff_id, application: application },
        })
    },

    // 更新
    update(apiconf, staff_id, application) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "application/update",
            query: { staff_id: staff_id, application: application },
        })
    },

    // 明細作成
    detailCreate(apiconf, staff_id, application) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "application/detail/create",
            query: { staff_id: staff_id, application: application },
        })
    },

    // ファイル
    addAttachment(apiconf, staff_id, application, attached_files) {
        let formdata = [
            {
                is_json: true,
                set_token: true,
                column: "posted",
                value: {
                    staff_id: staff_id,
                    application: application,
                },
            }
        ]
        if (attached_files) {
            attached_files.forEach(attached => {
                formdata.push({ column: 'attached', value: attached });
            })
        }
        return api_common.callPostFormApi({
            conf: apiconf,
            path: "application/event/add/attachment",
            formdata: formdata,
        })
    },
    removeAttachment(apiconf, staff_id, application) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "application/event/rm/attachment",
            query: { staff_id: staff_id, application: application },
        })
    },
    previewFile(apiconf, staff_id, application_id, file_id, original) {
        return api_common.callPostResponseFile({
            conf: apiconf,
            path: "preview/application/file",
            query: {
                staff_id: staff_id,
                application_id: application_id,
                file_id: file_id,
                original: original,
            },
        })
    },

    // （My店舗）一覧取得
    searchMyShop(apiconf, staff_id, conditions) {
        return api_common.callPostApi({
            conf: apiconf,
            path: "myshop/application/list",
            query: { staff_id: staff_id, conditions: conditions },
        })
    },
}