<template>
  <div>
    <CommonHeader :parents="parents"></CommonHeader>
    <AppAlert
      v-if="parents.notifications_for_bar"
      :notifications_for_bar="parents.notifications_for_bar"
    />
    <div class="d-flex">
      <LeftMenu :parents="parents" />
      <div class="pa-6 contents">
        <div class="grid-card-title">店舗の状況確認</div>
        <div class="grid-card" v-for="(group, i) in confirmItems" :key="i">
          <div class="grid-card-item" v-for="key in group" :key="key">
            <ConfirmItems :type="key" />
          </div>
        </div>

        <div class="grid-card-title mt-10">設定・管理</div>
        <div class="grid-card" v-if="isHavingAdminManagerRoles()">
          <div class="grid-card-item">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">日常業務設定</div>
                <div class="mt-2">
                  すべての店舗に、業務を指示することができます。
                </div>
                <div class="d-flex flex-wrap" v-if="isNewTaskScheduleUi()">
                  <div class="card-link mt-4">
                    <button @click="pageMove('manage/pattern')">
                      業務スケジュール
                    </button>
                  </div>
                </div>
                <div class="d-flex flex-wrap" v-else>
                  <div class="card-link mt-4">
                    <div class="card-step">STEP1</div>
                    <button @click="pageMove('manage/task')">タスク</button>
                  </div>
                  <div class="card-link mt-4">
                    <div class="card-step">STEP2</div>
                    <button @click="pageMove('manage/template')">
                      タスクグループ
                    </button>
                  </div>
                  <div class="card-link mt-4">
                    <div class="card-step">STEP3</div>
                    <button @click="pageMove('manage/pattern')">
                      業務スケジュール
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-card-item" v-if="isHavingAdminManagerRoles()">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  チェックシート
                </div>
                <div class="mt-2">
                  日々のチェックシートを電子化することで、管理・提出が楽になります。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('manage/checksheet')">
                      チェックシート
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-card" v-if="isHavingAdminManagerRoles()">
          <div class="grid-card-item">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  臨時タスク設定
                </div>
                <div class="mt-2">
                  いつもの業務に、緊急の指示を差し込むことができます。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('manage/emergency')">
                      臨時タスク
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-card-item">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  個人タスク設定
                </div>
                <div class="mt-2">
                  個別のユーザーに、業務を指示することができます。
                </div>
                <div class="d-flex flex-wrap">
                  <div class="card-link mt-4">
                    <button @click="pageMove('manage/personal_task')">
                      個人タスク
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-card">
          <div class="grid-card-item" v-if="isHavingAdminManagerRoles()">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">
                  マニュアル管理
                </div>
                <div class="mt-2">業務のマニュアルを作成、管理できます。</div>
                <div class="card-link mt-4">
                  <button @click="pageMove('/manage/manual')">
                    マニュアル
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-card-item" v-if="isHavingAdminManagerRoles()">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">店舗一覧</div>
                <div class="mt-2">
                  V-Manageを利用中の店舗を確認できます。<br />
                  店舗ごとの業務内容・チェックシートを確認できます。
                </div>
                <div class="card-link mt-4">
                  <button @click="pageMove('/shops')">店舗一覧</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-card">
          <div class="grid-card-item" v-if="isHavingAdminManagerRoles()">
            <div class="card">
              <div class="card-head">
                <div class="card-head-title font-weight-bold">利用設定</div>
                <div class="mt-2">V-Manageの利用設定はこちらから行います。</div>
                <div class="d-flex">
                  <div class="card-link mt-4">
                    <button @click="pageMove('/settings/company')">
                      利用設定
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/components/service/PcHeader";
import AppAlert from "@/components/timemanagement/PcAppAlert.vue";
import LeftMenu from "@/components/timemanagement/PcLeftMenu.vue";
import ConfirmItems from "./PcHeadShopHomeConfirmItems";
export default {
  components: { CommonHeader, AppAlert, LeftMenu, ConfirmItems },
  props: ["parents"],
  setup() {
    const { getCompanySpecsUseRanking } = require("@/helpers/auth/spec");
    const { pageMove, isUseRequest } = require("@/helpers/common/common_util");
    const {
      isHavingAdminManagerRoles,
      isNewTaskScheduleUi,
    } = require("@/helpers/timemng/timemng_util");
    return {
      getCompanySpecsUseRanking,
      pageMove,
      isUseRequest,
      isHavingAdminManagerRoles,
      isNewTaskScheduleUi,
    };
  },
  computed: {
    confirmItems() {
      let items = ["report", "dashboard", "confirmation"];
      if (this.isUseRequest()) items.push("request");
      if (this.getCompanySpecsUseRanking().head) items.push("ranking");
      return new Array(Math.ceil(items.length / 2))
        .fill()
        .map((_, i) => items.slice(i * 2, (i + 1) * 2));
    },
  },
};
</script>

<style scoped>
.v-application {
  background-color: unset !important;
}

.v-application ul {
  padding-left: 0 !important;
}

.v-application a {
  color: inherit;
}

.contents {
  width: calc(100% - 116px);
}

.grid-card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}
</style>
